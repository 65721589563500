<template>
   <b-modal
      id="modal-prospect-created"
      cancel-variant="outline-secondary"
      scrollable
      :title="
         isUpdate ? 'Modification du prospect' : 'Ajouter un nouvel prospect'
      "
   >
   {{updateK}}
      <b-form>
         <div class="flex flex-col relative">
            <OnlySelectForm
               label="Domaine d'activité"
               :require_form="true"
               page="prospect"
               :modelValue="dataInput.domaine"
               @update:modelValue="dataInput.domaine_id = $event"
               name="domaine_id"
               :options="listDomaine"
               placeholder="Entrez le domaine d'activité du prospect."
               :validate="validateDataProspect"
               :error="errorInput"
               :labelOptions="'libelle'"
               :multiple="false"
            />

            <!-- Nom -->
            <only-form
               label="Nom du prospect"
               :require_form="true"
               page="prospect"
               :modelValue="dataInput.nom"
               @update:modelValue="dataInput.nom = $event"
               name="nom"
               placeholder="Sika"
               :validate="validateDataProspect"
               :error="errorInput"
            />

            <!-- Prenoms -->
            <only-form
               label="Prenoms du prospect"
               :require_form="true"
               page="prospect"
               :modelValue="dataInput.prenoms"
               @update:modelValue="dataInput.prenoms = $event"
               name="prenoms"
               placeholder="John"
               :validate="validateDataProspect"
               :error="errorInput"
            />

            <!-- Email -->
            <only-form
               label="Adresse mail"
               type="email"
               :require_form="true"
               page="prospect"
               :modelValue="dataInput.email"
               @update:modelValue="dataInput.email = $event"
               name="email"
               placeholder="sika@exemple.com"
               :validate="validateDataProspect"
               :error="errorInput"
            />

            <!-- Code postal -->
            <only-form
               label="Code postal"
               :require_form="true"
               page="prospect"
               :modelValue="dataInput.indicateur"
               @update:modelValue="dataInput.indicateur = $event"
               name="indicateur"
               placeholder="00225"
               :validate="validateDataProspect"
               :error="errorInput"
            />

            <!-- Contact -->
            <only-form
               label="Contact"
               page="prospect"
               name="contact"
               :modelValue="dataInput.contact"
               @update:modelValue="dataInput.contact = $event"
               placeholder="07 07 20 21 32"
               :validate="validateDataProspect"
               :require_form="true"
               :error="errorInput"
            />

            <!-- type de prospect -->
            <only-select-form
               label="Type de prospect"
               :require_form="true"
               page="prospect"
               :modelValue="dataInput.type_client"
               @update:modelValue="dataInput.type_client = $event"
               name="type_client"
               :options="[{ title: 'Entreprise' }, { title: 'Particuler' }]"
               placeholder="Entreprise"
               :validate="validateDataProspect"
               :error="errorInput"
               :labelOptions="'title'"
               :multiple="false"
            />

            <!-- Status lier au prospect -->
            <OnlySelectForm
               label="Niveau d'interêt"
               :require_form="true"
               page="prospect"
               :modelValue="dataInput.status"
               @update:modelValue="dataInput.status = $event"
               name="status"
               :options="listStatus"
               placeholder="Selectionner le niveau d'interêt du prospect."
               :validate="validateDataProspect"
               :error="errorInput"
               :labelOptions="'title'"
               :multiple="false"
            />

            <!-- More information -->
            <div class="flex flex-col gap-4 w-full">
               <span class="w-full border-b-[0.5px] p-[8px] font-bold "
                  >Plus d'informations</span
               >

               <div>
                  <!-- Brith born  -->
                  <only-date-form
                     label="Date de naissance"
                     :require_form="false"
                     page="prospect"
                     :modelValue="dataInput.date_naissance"
                     @update:modelValue="dataInput.date_naissance = $event"
                     name="date_naissance"
                     placeholder="01/01/1998"
                     :validate="null"
                     :error="errorInput"
                  />

                  <!-- Lieu de naissance -->
                  <only-form
                     label="Lieu de naissance"
                     page="prospect"
                     :modelValue="dataInput.lieu_naissance"
                     @update:modelValue="dataInput.lieu_naissance = $event"
                     name="lieu_naissance"
                     placeholder="Abidjan, Cocody"
                     :validate="null"
                     :require_form="false"
                     :error="errorInput"
                  />

                  <!-- Sexe du prospect -->
                  <only-select-form
                     label="Sexe du prospect"
                     :require_form="false"
                     page="prospect"
                     :modelValue="dataInput.sexe"
                     @update:modelValue="dataInput.sexe = $event"
                     name="sexe"
                     :options="[{ title: 'Homme' }, { title: 'Femme' }]"
                     placeholder="Homme"
                     :validate="null"
                     :error="errorInput"
                     :labelOptions="'title'"
                     :multiple="false"
                  />

                  <!-- Situation Matrimonial -->
                  <only-select-form
                     label="Situation Matrimoniale"
                     :require_form="false"
                     page="prospect"
                     :modelValue="dataInput.situation_matrimoniale"
                     @update:modelValue="
                        dataInput.situation_matrimoniale = $event
                     "
                     name="situation_matrimoniale"
                     :options="[
                        { title: 'Celibataire' },
                        { title: 'Marié' },
                        { title: 'divocé' },
                     ]"
                     placeholder="Celibataire"
                     :validate="null"
                     :error="errorInput"
                     :labelOptions="'tittle'"
                     :multiple="false"
                  />

                  <!-- Nationnalité -->
                  <only-form
                     label="Nationnalité"
                     page="prospect"
                     :modelValue="dataInput.nationalite"
                     @update:modelValue="dataInput.nationalite = $event"
                     name="nationalite"
                     placeholder="Ivoirienne"
                     :validate="null"
                     :require_form="false"
                     :error="errorInput"
                  />

                  <!-- Nom de l'entreprise -->
                  <only-form
                     label="Nom de l'entreprise"
                     page="prospect"
                     :modelValue="dataInput.company_name"
                     @update:modelValue="dataInput.company_name = $event"
                     name="company_name"
                     placeholder="Qenium"
                     :validate="null"
                     :require_form="false"
                     :error="errorInput"
                  />

                  <!-- Nom de l'entreprise -->
                  <only-form
                     label="Profession"
                     page="prospect"
                     :modelValue="dataInput.profession"
                     @update:modelValue="dataInput.profession = $event"
                     name="profession"
                     placeholder="Community Manager"
                     :validate="null"
                     :require_form="false"
                     :error="errorInput"
                  />
               </div>
            </div>
         </div>
      </b-form>
      <template #modal-footer>
         <b-button
            :disabled="state.loading === true ? true : false"
            variant="primary"
            type="submit"
            @click="prospectCreatedToEdit"
         >
            <span v-if="state.loading === false">
               {{ isUpdate ? 'Modifier' : 'Ajouter' }}
            </span>
            <b-spinner
               v-if="state.loading === true"
               label="Spinning"
            ></b-spinner>
         </b-button>
      </template>
   </b-modal>
</template>

<script>
import { BForm, BButton, BSpinner } from 'bootstrap-vue';
import { reactive, ref, onMounted, computed } from '@vue/composition-api';
import URL from '@/views/pages/request/index';
import axios from 'axios';
import { VueTelInput } from 'vue-tel-input';
import { toast_sucess, toast_update } from '@/utils/qToast';
import OnlyForm from '@/components/forms/onlyForm.vue';
import OnlySelectForm from '@/components/forms/onlySelectForm.vue';
import OnlyDateForm from '@/components/forms/onlyDateForm.vue';
import moment from 'moment';

export default {
   components: {
      OnlyForm,
      BButton,
      BSpinner,
      OnlySelectForm,
      OnlyDateForm,
      BForm,
   },
   props: ['prospects', 'isUpdate', 'toUpdateData'],
   setup(props, { root }) {
      const state = reactive({
         perPage: 30,
         currentPage: 1,
         filter: '',
         success: false,
         empty: false,
         warring: false,
         loading: false,
      });
      const dataInput = reactive({
         nom: '',
         prenoms: '',
         email: '',
         indicateur: '',
         contact: '',
         type_client: '',
         domaine_id: '',
         status: '',
         date_naissance: '',
         lieu_naissance: '',
         sexe: '',
         nationalite: '',
         company_name: '',
         code_postal: '',
         profession: '',
      });
      const listStatus = reactive([
         {
            title: 'Interessé',
         },
         {
            title: 'Pas trop interessé',
         },
         {
            title: 'Pas interessé',
         },
      ]);

      const errorInput = reactive({
         path: '',
         message: '',
      });

      const updateK = computed(() => {
         if(props.isUpdate){
            dataInput.domaine_id = {libelle: props.toUpdateData['domaine_id']}
            dataInput.nom = props.toUpdateData['nom']
            dataInput.prenoms = props.toUpdateData['prenoms']
            dataInput.email = props.toUpdateData['email']
            dataInput.indicateur = props.toUpdateData['indicateur']
            dataInput.contact = props.toUpdateData['contact']
            dataInput.type_client = {title: props.toUpdateData['type_client']}
            
            dataInput.status = {title: props.toUpdateData['status']}
            dataInput.date_naissance = props.toUpdateData['date_naissance']
            dataInput.lieu_naissance = props.toUpdateData['lieu_naissance']

            dataInput.nationalite = props.toUpdateData['nationalite']
            dataInput.company_name = props.toUpdateData['company_name']
            dataInput.code_postal = props.toUpdateData['code_postal']

            dataInput.profession = props.toUpdateData['profession']

            
         }

})

      const listDomaine = ref([]);

      onMounted(() => {
         getDomaine();
         console.log('test')
      });

      

      // Get all domaine Activite
      const getDomaine = async () => {
         try {
            const { data } = await axios.get(URL.DOMAINE_PROSPECTION_LIST);
            if (data) {
               listDomaine.value = data.domaine;
            }
         } catch (error) {
            console.log(error);
         }
      };

      const validateDataProspect = async (request) => {
         let error = null;
         let message = '';
         const fieldsNoEmpy = [
            'domaine_id',
            'nom',
            'prenoms',
            'email',
            'indicateur',
            'contact',
            'type_client',
         ];
         const fieldsDigit = ['indicateur', 'contact'];
         const regexDigit = /^[\+\-]?\d*\.?\d+(?:[Ee][\+\-]?\d+)?$/;
         const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

         for (let i = 0; i < fieldsNoEmpy.length; i++) {
            const el = fieldsNoEmpy[i];

            message = 'Ce champs ne dois pas être vide.';
            if (request[el] === '') {
               errorInput.path = el;
               errorInput.message = message;
               return {
                  error: true,
               };
            } else if (
               request['email'] !== '' &&
               !regexEmail.test(request['email'])
            ) {
               message = 'Veillez entrez un email valide';
               errorInput.path = 'email';
               errorInput.message = message;
               return {
                  error: true,
               };
            } else if (
               request['indicateur'] !== '' ||
               request['contact'] !== ''
            ) {
               for (let s = 0; s < fieldsDigit.length; s++) {
                  const el = fieldsDigit[s];
                  message = 'Ce champs require des nombres entier.';
                  if (!regexDigit.test(request[el])) {
                     errorInput.path = el;
                     errorInput.message = message;
                     return {
                        error: true,
                     };
                  }
               }
            }
         }
         errorInput.path = '';
         errorInput.message = '';

         return { error: error };
      };

      const createPospect = async (to, id) => {
         state.loading = true;
         try {
            const { error } = await validateDataProspect(dataInput);
            if (error) {
               state.loading = false;
               return errorInput;
            }

            dataInput.type_client = dataInput.type_client['title'];
            dataInput.domaine_id = dataInput.domaine_id['id'];
             dataInput.status = dataInput.status['title'];
            dataInput.situation_matrimoniale = dataInput.situation_matrimoniale['title'];

            const fetcher = async (url) => {
               const { data } = await axios.post(url, dataInput);
               return data;
            };

            let data;
            to === 'created'
               ? (data = await fetcher(URL.PROSPECT_CREATE))
               : (data = await fetcher(URL.PROSPECT_UPDATE));

            if (data) {
               if (to === 'created') {
                  data.user.created_at = moment(
                     String(data.user.created_at)
                  ).format('DD-MM-YYYY');
                  props.prospects.unshift(data.user);
                  toast_sucess(
                     root,
                     'success',
                     'top-right',
                     'Prospect creer avec succès.'
                  );
               }else{
                  props.prospects.forEach(el => {
                     if(el.id === id){
                        
                     }
                  });


                  for (let i = 0; i < props.prospects.length; i++) {
                  const el = props.prospects[i];

                  if (el.id === dataInput.id) {
                     const prospectDataKey = Object.keys(el);
                     const registerDataKey = Object.keys(data.prospection);

                     for (let j = 0; j < prospectDataKey.length; j++) {
                        const jl = prospectDataKey[j];

                        if (jl === registerDataKey[j]) {
                           el[jl] = data.prospect[jl];
                        } else {
                           el[jl] = null;
                        }
                     }
                  }
               }

               }

               root.$bvModal.hide('modal-prospect-created');

               state.loading = false;
            }
         } catch (error) {
            console.log(error);
            state.loading = false;
         }
      };

      const updateProspect = async(to, id) => {
         await createPospect(to, id);
      };

      const prospectCreatedToEdit = async (id) => {
         if (props.isUpdate) {
            await updateProspect('updated', id);
         } else {
            await createPospect('created', id);
         }
      };

      return {
         state,
         errorInput,
         validateDataProspect,
         createPospect,
         dataInput,
         listDomaine,
         prospectCreatedToEdit,
         listStatus,
         updateK
      };
   },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
