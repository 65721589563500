<template>
   <section>
      <q-permissions
         :__PERMISSIONS__="permStatus"
         :page="'prospect'"
         :index="'index'"
      />
      <div class="" v-if="permStatus">
         <div class="">
            <q-prospect-add-to-edit :prospects="prospects"  :isUpdate="isUpdate" :toUpdateData="toUpdateData" />

            <b-card no-body class="">
               <!-- Le haut du tableau contenant les barre de recherche et bouton d'ajout de nouvelle taxe -->

               <b-row class="px-2">
                  <!-- Per Page -->

                  <b-col
                     cols="12"
                     md="6"
                     class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mt-1 "
                  >
                     <label>Entrées</label>

                     <v-select
                        v-model="state.perPage"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="perPageOptions"
                        :clearable="false"
                        class="per-page-selector d-inline-block ml-50 mr-1"
                     />

                     <b-button
                        variant="primary"
                        v-b-modal.modal-prospect-created
                        @click="isUpdate = false"
                     >
                        <div class="flex gap-2">
                           <feather-icon icon="PlusIcon" class="mx-auto" />
                           Nouveau prospect
                        </div>
                     </b-button>
                  </b-col>

                  <!-- Search -->

                  <b-col cols="12" md="6" class="mt-1">
                     <div class="d-flex align-items-center justify-content-end">
                        <b-input-group class="input-group-merge">
                           <b-input-group-prepend is-text>
                              <feather-icon icon="SearchIcon" />
                           </b-input-group-prepend>
                           <b-form-input
                              v-model="state.filtre"
                              class="d-inline-block mr-1"
                              placeholder="Rechercher par : nom d'prospect, lieu, entreprise..."
                           />
                        </b-input-group>
                     </div>
                  </b-col>
               </b-row>

               <q-loader-table
                  :success="state.success"
                  :empty="state.empty"
                  :warring="state.warring"
               />

               <b-table
                  hover
                  responsive
                  primary-key="id"
                  :per-page="state.perPage"
                  :current-page="state.currentPage"
                  :items="prospects"
                  :fields="tableColumns"
                  :filter="state.filter"
                  show-empty
                  empty-text="Aucun projet enregistré"
                  class="bg-white  text-left"
                  v-if="state.success === true"
               >
                  <template #cell(nom)="data">
                     <span :id="`client-row-${data.item.id}-nom-icon`">
                        {{
                           (data.item.nom + ' ' + data.item.prenoms) | toSubtr
                        }}
                     </span>
                     <b-tooltip
                        v-if="
                           `${data.item.nom} ${data.item.prenoms}`.length > 15
                        "
                        :title="data.item.nom + ' ' + data.item.prenoms"
                        class="cursor-pointer"
                        :target="`client-row-${data.item.id}-nom-icon`"
                     />
                  </template>

                  <template #row-details="data">
                     <div no-body class="">
                        <div class="grid grid-col-3">
                           <div
                              v-for="(info, index) in MoreInfoProspect"
                              :key="index"
                           >
                              <div
                                 v-if="info.id === data.item.id"
                                 v-for="(pros, index) in info.prospect"
                                 :key="index"
                              >
                                 <div
                                    class="flex w-full gap-4 border-t-[0.5px] border-gray-300 items-center"
                                 >
                                    <span class="bg-gray-200 w-[40%] p-[8px] ">
                                       {{ pros.title }}
                                    </span>
                                    <span class="" v-if="pros.value"> {{  pros.value  }} </span>
                                    <span v-else class="text-xs font-italic"> Pas defini</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </template>

                  <!-- Column: Actions -->
                  <template #cell(actions)="data">
                     <div class="text-nowrap flex gap-2 py-1">
                        <feather-icon
                           v-b-modal.modal-prospect-created
                           icon="Edit3Icon"
                           size="16"
                           class="cursor-pointer"
                           
                           @click="
            (event) => {
               $emit('update:updateProspect', updateProspect(data.item));
            }
         "
                        />

                        <feather-icon
                           icon="TrashIcon"
                           class="ml-1 cursor-pointer"
                           size="16"
                           @click="deleteProspect(data.item.id)"
                        />

                        <label
                           :for="`invoice-row-${data.item.id}-hideAndShow-icon`"
                        >
                           <feather-icon
                              v-model="data.detailsShowing"
                              @change="data.toggleDetails"
                              :icon="
                                 data.detailsShowing ? 'EyeIcon' : 'EyeOffIcon'
                              "
                              size="16"
                              class="cursor-pointer ml-1"
                           />
                        </label>

                        <b-form-checkbox
                           :id="`invoice-row-${data.item.id}-hideAndShow-icon`"
                           v-model="data.detailsShowing"
                           plain
                           class="vs-checkbox-con hidden"
                           @change="data.toggleDetails"
                        >
                        </b-form-checkbox>
                     </div>
                  </template>
               </b-table>

               <!-- Les boutons de pagination -->

               <div class="mx-2 my-2 pb-1">
                  <b-row>
                     <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                     >
                        <span class="text-muted"></span>
                     </b-col>

                     <!-- Pagination -->

                     <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                     >
                        <b-pagination
                           v-model="state.currentPage"
                           :total-rows="prospects.length"
                           :per-page="state.perPage"
                           first-number
                           last-number
                           class="mb-0 mt-1 mt-sm-0"
                           prev-class="prev-item"
                           next-class="next-item"
                        >
                           <template #prev-text>
                              <feather-icon icon="ChevronLeftIcon" size="18" />
                           </template>

                           <template #next-text>
                              <feather-icon icon="ChevronRightIcon" size="18" />
                           </template>
                        </b-pagination>
                     </b-col>
                  </b-row>
               </div>
            </b-card>
            {{ __NEXT }}
         </div>

         {{ __NEXT }}
      </div>
   </section>
</template>

<script>
import {
   BRow,
   BCol,
   BModal,
   BFormInput,
   BFormGroup,
   BButton,
   VBModal,
   BForm,
   BLink,
   BFormCheckbox,
   BInputGroup,
   BInputGroupAppend,
   BImg,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { required, email } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import vSelect from 'vue-select';
import URL from '@/views/pages/request';
import axios from 'axios';
import moment from 'moment';
import { VueTelInput } from 'vue-tel-input';
import { toast_sucess } from '@/utils/qToast';
import QLoaderTable from '@/components/__partials/loaders/qLoaderTable.vue';
import { computed, reactive, ref, onMounted } from '@vue/composition-api';
import QPermissions from '../../access/qPermissions.vue';
import QButtonCreated from '@/components/qButtonCreated.vue';
import QProspectAddToEdit from './qProspectAddToEdit.vue';

export default {
   components: {
      VueTelInput,
      vSelect,
      BRow,
      BCol,
      BFormInput,
      moment,
      BButton,
      BModal,
      BFormGroup,
      VBModal,
      BForm,
      BImg,
      BLink,
      BForm,
      BFormCheckbox,
      BInputGroup,
      BInputGroupAppend,
      // validations
      ValidationProvider,
      ValidationObserver,
      QLoaderTable,
      QPermissions,
      QButtonCreated,
      QProspectAddToEdit,
   },

   filters: {
      toSubtr(value) {
         if (!value) return '';
         value = value.toString();
         if (value.length > 15) {
            return value.substring(0, 15) + '...';
         } else {
            return value;
         }
      },
   },

   mounted() {
      document.title = 'Prospects - Ediqia';
      google.maps.event.addDomListener(window, 'load', initialize);
   },

   methods: {
      format_date(value) {
         if (value) {
            return moment(String(value)).format('DD-MM-YYYY');
         }
      },

      initialize() {
         var input = document.getElementById('autocomplete_search');
         var autocomplete = new google.maps.places.Autocomplete(input);
         autocomplete.addListener('place_changed', function() {
            var place = autocomplete.getPlace();
            // place variable will have all the information you are looking for.
            $('#lat').val(place.geometry['location'].lat());
            $('#long').val(place.geometry['location'].lng());
            this.localisation = place;
            localStorage.setItem('place', JSON.stringify(place));
            // console.log(this.address)
         });
      },
   },
   setup(props, { root }) {
      const state = reactive({
         perPage: 30,
         currentPage: 1,
         filter: '',
         success: false,
         empty: false,
         warring: false,
         loading: false,
      });

      const prospects = ref([]);
      const tableColumns = reactive([
         {
            key: 'nom',
            label: 'Libelle & categorie',
         },
         {
            key: 'email',
            label: 'email',
         },
         {
            key: 'description',
            label: 'description',
         },
         {
            key: 'created_at',
            label: "date d'ajout",
         },
         {
            key: 'actions',
            label: 'Actions',
         },
      ]);
      const perPageOptions = [30, 50, 100, 150, 250, 500];
      const GIVE = ref(false);
      const MoreInfoProspect = ref([]);

      onMounted(async () => {
         await getAllProspect();
      });

      const __NEXT = computed(() => {
         if (GIVE.value === true) {
            prospects.value.length === 0
               ? ((state.empty = true), (state.success = false))
               : ((state.success = true), (state.empty = false));
         }
         return;
      });

      const getAllProspect = async () => {
         try {
            const { data } = await axios.get(URL.PROSPECT_LIST);
            if (data) {
               console.log(data);

               data.prospects.forEach((el) => {
                  el.created_at = moment(String(el.created_at)).format(
                     'DD-MM-YYYY'
                  );
                  MoreInfoProspect.value.push({
                     id: el.id,
                     prospect: [
                        {
                           title: 'Nom&prenoms',
                           value: el.nom + ' ' + el.prenoms,
                        },
                        { title: 'Adresse email', value: el.email },
                        {
                           title: 'Contact',
                           value: el.indicateur + ' ' + el.contact,
                        },
                        { title: 'type de prospect', value: el.type_client },
                        { title: "Niveau d'intêret", value: el.statuts },
                        { title: "Date de naissance", value: el.date_naissance },
                        { title: "Lieu de naissance", value: el.lieu_naissance },
                        { title: "Sexe", value: el.sexe },
                        { title: "Situation natrimoliale", value: el.situation_matrimoniale },
                        { title: "Nationnalité", value: el.nationnalite },
                        { title: "Nom de son entreprise", value: el.company_name },
                        { title: "Profession", value: el.profession },
                        
                     ],
                  });
               });

               prospects.value = data.prospects;

               GIVE.value = true;
            }
         } catch (error) {
            console.log(error);
            state.warring = true;
         }
      };

      // Delete specific prospect
      const deleteProspect = (id) => {
         root
            .$swal({
               title: 'Êtes vous sûr?',
               text: 'Cette prospect sera supprimé définitivement !',
               icon: 'warning',
               showCancelButton: true,
               confirmButtonText: 'Oui, supprimer-le!',
               customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
               },
               buttonsStyling: false,
            })
            .then(async (result) => {
               if (result.value) {
                  try {
                     const dataDelete = {
                        id: id,
                     };
                     const { data } = await axios.post(
                        URL.PROSPECT_DESTROY,
                        dataDelete
                     );
                     if (data) {
                        prospects.value = prospects.value.filter((item) => {
                           return item.id !== id;
                        });
                        GIVE.value = true;
                        console.log(data);
                     }
                  } catch (error) {
                     console.log(error);
                  }
               }
            });
      };
      const isUpdate = ref('false')
      const toUpdateData = ref([])
      const updateProspect = (prospect) => {
         isUpdate.value = true
         toUpdateData.value = prospect
         // console.log('prospect : ', prospect);
         // setTimeout(() => {
         //    const getProspectKey = Object.keys(prospect);
         //    let __getProspectKey = [];
         //    let isExistInput = [];

         //    getProspectKey.forEach((el) => {
         //       if (document.querySelector('#prospect-' + el)) {
         //          __getProspectKey.push(el);
         //          isExistInput.push(document.querySelector('#prospect-' + el));
         //       }
         //    });

            
         //    isExistInput.forEach((el, index) => {
         //       el.value = prospect[__getProspectKey[index]];
               
         //    });
         // }, 500);

         // q.value = 'test'
      };

      

      const permStatus = computed(() => {
         return root.$store.state.qPermissions.statuts;
      });

      return {
         permStatus,
         __NEXT,
         state,
         perPageOptions,
         tableColumns,
         prospects,
         MoreInfoProspect,
         deleteProspect,
         updateProspect,
         toUpdateData,
         isUpdate
      };
   },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
